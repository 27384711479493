<template>
  <div>
    <div class="box">
      <p class="subtitle is-6">
        Data guarantee has been purchased for this vehicle
      </p>
      <table class="table is-fullwidth is-striped">
        <thead>
          <tr>
            <td>Value (£)</td>
            <td>Months</td>
            <td>Date</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{{ formatMoney(data.dgLevel) }}</td>
            <td>{{ data.dgTerm }}</td>
            <td>{{ formatDate(data.createdAt) }}</td>
          </tr>
        </tbody>
      </table>
      <p>
        This data guarantee provides protection from specified financial losses
        in the event that Experian provides specific inaccuracies in the data
      </p>
    </div>
  </div>
</template>

<script>
import { formatMoney } from 'accounting'
import { formatDate } from '@/utils/date.utils.ts'
export default {
  name: 'AutoCheckGuarantee',
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  methods: {
    formatDate,
    formatMoney(value) {
      return formatMoney(value, '£', 0)
    }
  }
}
</script>
